// **************************************************
// Game Circle
// **************************************************

@import '../../../scss/import';

.game-circle {
	position: relative;
	width: 202px;
	height: 202px;
	background-color: $cgp_white;
	border-radius: 100%;

	// Tablet L
	@media #{$breakpoint-md-only} {
		width: 170px;
		height: 170px;
	}

	// Tablet P
	@media #{$breakpoint-sm-only} {
		width: 130px;
		height: 130px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		width: 100px;
		height: 100px;
	}

	// ##################################################
	// Background Circle

	&::after,
	&::before,
	&__bg-circle {
		@extend %content;
		@include horizontal-vertical-align(absolute);
		background-color: $cgp_blue_light_1;
		opacity: 0.8;
		border-radius: 100%;
		height: calc(100% + 30%);
		width: calc(100% + 30%);
		z-index: -1;
	}

	// ##################################################
	// Background Circle

	&::after {
		opacity: 0.3;
		box-shadow: 0 0 60px rgba(0, 0, 0, 0.5);
		height: calc(100% + 74%);
		width: calc(100% + 74%);
	}

	// ##################################################
	// Background Circle

	&__bg-circle {
		height: calc(100% + 204%);
		width: calc(100% + 204%);
		background-color: rgba($cgp_white, 0.25);
		opacity: 0.16;

		// ##################################################

		&:nth-child(2) {
			height: calc(100% + 312%);
			width: calc(100% + 312%);
			opacity: 0.06;
		}
	}

	// ##################################################

	.icon-ticket {
		// Tablet P
		@media #{$breakpoint-sm-only} {
			width: 65px;
			height: 46px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			width: 55px;
			height: 46px;
		}
	}

	// ##################################################

	.icon-crossed-fingers {
		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			width: 52px;
			height: 82px;
		}
	}

	// ##################################################

	&__calender {
		position: relative;
		width: 110px;
		height: 96px;
		border: 6px solid $cgp_blue;
		border-radius: 12px;
		color: $cgp_blue_light_1;
		text-align: center;
		display: flex;
		flex-direction: column;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 700;
		line-height: 1;
		font-family: $font_secondary;
		text-shadow: 0 2px 0 rgba(0, 161, 228, 0.17);

		// Mobile
		@media #{$breakpoint-xs-only} {
			width: 100px;
			height: 84px;
			border-width: 4px;
		}

		// ##################################################
		// Line At Top Left

		&::after,
		&::before {
			content: '';
			position: absolute;
			left: 20px;
			top: -5px;
			transform: translateY(-100%);
			background-color: $cgp_blue;
			height: 12px;
			width: 6px;
			border-radius: 12px 12px 0 0;

			// Mobile
			@media #{$breakpoint-xs-only} {
				width: 4px;
				top: -3px;
			}
		}

		// ##################################################
		// Line At Top Right

		&::after {
			left: inherit;
			right: 20px;
		}

		// ##################################################

		&-month {
			padding: 5px 2px 4px;
			border-bottom: 6px solid $cgp_blue;

			// Mobile
			@media #{$breakpoint-xs-only} {
				border-width: 4px;
			}
		}

		// ##################################################

		&-date {
			font-size: 46px;
			font-weight: 800;
			padding: 2px;
			display: flex;
			flex: auto;
			align-items: center;
			justify-content: center;

			// Mobile
			@media #{$breakpoint-xs-only} {
				font-size: 32px;
			}
		}
	}
}
