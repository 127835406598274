// **************************************************
// Game Failed Module
// **************************************************

@import '../../../scss/import';

.game-failed {
	overflow: hidden;
	text-align: center;
	padding: 40px 20px;

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 0 20px 20px;
	}

	// ##################################################

	.container {
		position: relative;
		max-width: 696px;
	}

	// ##################################################

	&__header {
		// ##################################################

		.icon {
			margin: 20px auto 0;
		}

		// ##################################################

		.title--h3 {
			@extend %fontsize28;
			text-transform: none;
			margin-top: 10px;
		}
	}

	// ##################################################

	&__content {
		position: relative;
		margin: 12px 0 80px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-bottom: 50px;
		}

		.title--h3 {
			text-transform: none;
		}
	}

	// ##################################################

	.game-circle {
		margin: 78px auto 0;
		width: 156px;
		height: 156px;

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			width: 122px;
			height: 122px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 60px;
		}

		// ##################################################

		.game-circle__bg-circle {
			// Desktop and Up
			@media #{$breakpoint-lg-up} {
				height: calc(100% + 400%);
				width: calc(100% + 400%);
			}

			// ##################################################

			&:nth-child(2) {
				// Desktop and Up
				@media #{$breakpoint-lg-up} {
					height: calc(100% + 582%);
					width: calc(100% + 582%);
				}
			}
		}
	}

	// ##################################################

	.section-footer {
		margin-top: auto;
		padding-left: 42px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding-left: 0;
		}
	}

	// ##################################################

	.editor-text {
		max-width: 336px;
		width: 100%;
		margin: 76px auto 0;
		color: $cgp_blue_light_3;

		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: 100%;
			margin-top: 60px;
		}
	}

	// ##################################################

	&__bg-element {
		opacity: 0.3;

		// ##################################################

		.background-circle {
			position: absolute;
			left: -74%;
			top: 32%;

			// Desktop
			@media #{$breakpoint-lg-only} {
				left: -54%;
			}

			// Tablet L
			@media #{$breakpoint-md-only} {
				left: -34%;
			}

			// Tablet P and Down
			@media #{$breakpoint-sm-down} {
				left: -10%;
			}

			// ##################################################

			&:nth-child(1) {
				// Desktop and Up
				@media #{$breakpoint-lg-up} {
					width: 126px;
					height: 126px;
				}
			}

			// ##################################################

			&:nth-child(2) {
				left: inherit;
				right: -42%;
				top: -6%;

				// Tablet L
				@media #{$breakpoint-md-only} {
					right: -12%;
				}

				// Tablet P and Down
				@media #{$breakpoint-sm-down} {
					right: -10%;
				}
			}
		}
	}
}
