// **************************************************
// Game Code
// **************************************************

@import '../../../scss/import';

.game__code {
	position: relative;
	margin-top: 12px;
	text-align: center;
	max-width: 434px;

	// Mobile
	@media #{$breakpoint-xs-only} {
		max-width: 298px;
	}

	// ##################################################

	&-label {
		color: $cgp_blue_light_1;
		font-size: 20px;
		letter-spacing: 0.4px;
		margin-bottom: 14px;
	}

	// ##################################################

	&-input {
		@extend %abs_pos_fullwidth;
		opacity: 0;
		border: 0;
		z-index: 3;
		pointer-events: all;
	}

	// ##################################################

	&-display {
		font-size: 42px;
		width: 100%;
		height: 70px;
		border: 0;
		text-align: center;
		background-color: rgba($cgp_white, 0.15);
		border-radius: 50px;
		letter-spacing: 16.8px;
		text-transform: uppercase;
		font-weight: 700;
		line-height: 1.8;
		pointer-events: none;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		color: $cgp_white;

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 70px;
			font-size: 36px;
			letter-spacing: 14.8px;
			line-height: 2.1;
		}
	}

	// ##################################################

	.cursor {
		color: $cgp_white;
	}
}
