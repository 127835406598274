// ##################################################
// BACKGROUNDS
// ##################################################

.cover-bg {
	@extend %abs_pos_fullwidth;
	background-size: cover;
	background-position: center center;

	img {
		display: none;
	}
}

.covered {
	@extend %abs_pos_fullwidth;
	max-height: initial;
}

// ##################################################
// TEXT ALIGN
// ##################################################

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

// ##################################################
// SCREEN READER
// ##################################################

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.button {
	@extend %reset_button;
}

// ##################################################

.p-0 {
	padding: 0;
}

// ##################################################

.mx-auto {
	width: 100%;
	display: block;
	margin: 0 auto;
}

// ##################################################

.f-auto {
	flex: auto;
	display: flex;
	flex-direction: column;
}

// ##################################################
// TEXT
// ##################################################

.text {
	// ##################################################

	&--9 {
		@extend %fontsize9;
	}

	// ##################################################

	&--13 {
		@extend %fontsize13;
	}

	// ##################################################

	&--14 {
		@extend %fontsize14;
	}

	// ##################################################

	&--16 {
		@extend %fontsize16;
	}

	// ##################################################

	&--18 {
		@extend %fontsize18;
	}

	// ##################################################

	&--20 {
		@extend %fontsize20;
	}

	// ##################################################

	&--24 {
		@extend %fontsize24;
	}

	// ##################################################

	&--28 {
		@extend %fontsize28;
	}

	// ##################################################

	&--bold {
		font-weight: 700;
	}

	// ##################################################

	&--md {
		font-weight: 500;
	}

	// ##################################################

	&--uppercase {
		text-transform: uppercase;
	}
}

// ##################################################
// VISIBILITY
// ##################################################

.hide {
	display: none;
}

.show {
	display: block;
}

.d-flex {
	display: flex;
}

.d-flex-cc,
.d-flex-center-center {
	@extend %flex_center_center;
}

.hidden {
	// Below 767px
	&-mobile {
		// Mobile
		@media #{$breakpoint-xs-only} {
			display: none;
		}
	}

	// ##################################################

	// Below 1023px
	&-tablet-p {
		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			display: none;
		}
	}
}

.visible {
	// Below 1013px
	&-mobile {
		display: none;

		// Mobile
		@media #{$breakpoint-xs-only} {
			display: block;
		}
	}

	// ##################################################

	// Below 1023px
	&-tablet-p {
		display: none;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			display: block;
		}
	}
}

.validation-message {
	@extend %fontsize18;
	text-align: center;
	color: $cgp_validation_failed;
	margin-top: 10px;
	display: none;

	.validation-failed & {
		display: block;
	}

	// ##################################################

	p {
		display: inline-block;
		padding-left: 22px;
		position: relative;

		// ##################################################

		// Warning icon
		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 3px;
			width: 14px;
			height: 14px;
			border-radius: 100%;
			background: url('data:image/svg+xml,#{$icon_warning}') no-repeat center center / contain;
		}
	}
}
