//**********************************************************
// Personal Data
//**********************************************************

@import '../../../scss/import';

.personal-data {
	position: relative;
	text-align: center;
	overflow: hidden;
	padding: 54px 0;

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 0 20px 40px;
	}

	// ##################################################

	.container {
		max-width: 670px;
		position: relative;
	}

	// ##################################################

	&__header {
		display: flex;
		align-items: center;
		flex-direction: column;
		max-width: 994px;
		width: 100%;
		margin: 0 auto;

		// Tablet L and Down
		@media #{$breakpoint-md-down} {
			max-width: 670px;
		}
	}

	// ##################################################

	.passes {
		margin-top: 34px;
	}

	// ##################################################

	&__form {
		margin-bottom: 60px;

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			margin-bottom: 32px;
		}
	}

	// ##################################################

	&__button {
		margin: 34px 0 0;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin: 20px 0 0;
		}
	}

	// ##################################################

	.section-footer {
		padding-left: 48px;
		margin-top: auto;

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding-left: 0;
		}
	}

	// ##################################################

	.radiobox-inline {
		margin: 30px 0;
	}

	// ##################################################

	.checkbox {
		text-align: left;
		padding: 0 14px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding: 0;
		}

		// ##################################################

		&__label {
			font-size: 13px;
			letter-spacing: 0.26px;
			line-height: 1.23;
			padding-left: 36px;
			margin-bottom: 20px;

			// ##################################################

			&::before {
				width: 26px;
				height: 26px;
				top: 0;
			}

			// ##################################################

			&::after {
				width: 14px;
				height: 14px;
				left: 6px;
				top: 6px;
			}
		}
	}

	// ##################################################

	&__bg-element {
		opacity: 0.6;

		// ##################################################

		.background-circle {
			position: absolute;
			left: -42%;
			top: 7%;

			// Desktop
			@media #{$breakpoint-lg-up} {
				width: 125px;
				height: 125px;
			}

			// Tablet P and Down
			@media #{$breakpoint-sm-down} {
				left: -10%;
			}

			// ##################################################

			&:nth-child(2) {
				left: inherit;
				right: -43%;
				top: 54%;

				// Desktop and Up
				@media #{$breakpoint-lg-up} {
					width: 106px;
					height: 106px;
				}

				// Tablet P and Down
				@media #{$breakpoint-sm-down} {
					right: -10%;
				}
			}
		}
	}
}
