// **************************************************
// Registration Module
// **************************************************

@import '../../../scss/import';

.registration {
	overflow: hidden;
	text-align: center;
	padding: 32px 0 40px;

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 0 20px 20px;
	}

	// ##################################################

	.container {
		position: relative;
		max-width: 780px;

		// Tablet P and Down
		@media #{$breakpoint-sm-down}{
			max-width: 580px;
		}
	}

	// ##################################################

	&__header {
		position: relative;

		// ##################################################

		.icon {
			margin: 30px auto 0;

			// Mobile
			@media #{$breakpoint-xs-only} {
				margin-top: 20px;
			}
		}

		// ##################################################

		.title--h3 {
			margin-top: 15px;
		}

		// ##################################################

		.background-circle {
			height: 50px;
			width: 50px;
			position: absolute;
			left: 15%;
			bottom: 32%;

			// Mobile
			@media #{$breakpoint-xs-only} {
				height: 30px;
				width: 30px;
			}
		}
	}

	// ##################################################

	&__button-list {
		display: grid;
		justify-items: center;
		grid-gap: 34px;
		margin-top: 50px;

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			margin-top: 40px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			grid-gap: 20px;
			margin-top: 30px;
		}

		// ##################################################

		.btn {
			// Tablet L and Up
			@media #{$breakpoint-md-up} {
				min-width: 312px;
			}
		}
	}

	// ##################################################

	&__content {
		position: relative;
		margin: 14px 0 80px;

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			margin-bottom: 50px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-bottom: 30px;
		}

		// ##################################################

		.background-circle {
			position: absolute;
			left: -22%;
			top: 10%;

			&:nth-child(2) {
				left: inherit;
				right: -22%;
				top: 13%;
			}
		}
	}

	// ##################################################

	.title--h3 {
		@extend %fontsize31;
		text-transform: none;
	}

	// ##################################################

	.editor-text {
		max-width: 432px;
		width: 100%;
		margin: 72px auto 0;
		letter-spacing: 0.32px;

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			margin-top: 30px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: 100%;
			margin-top: 20px;
		}
	}

	// ##################################################

	.section-footer {
		margin-top: auto;
		padding-left: 32px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding-left: 0;
		}
	}
}
