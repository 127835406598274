//**********************************************************
// INPUT FIELD
//**********************************************************

@import '../../../scss/import';

.input-field {
	width: 100%;
	max-width: 500px;
	position: relative;
	text-align: left;
	margin-bottom: 30px;

	// Mobile
	@media #{$breakpoint-xs-only} {
		max-width: 100%;
		margin-bottom: 20px;
	}

	// ##################################################

	&__inner {
		position: relative;
	}

	// ##################################################

	&__label {
		display: block;
		color: $cgp_white;
		margin: 0;
		position: absolute;
		left: 0;
		top: 5px;
		padding-left: 18px;
		transform: translate(16px, 7px);
		transition: all 0.3s ease;

		// ##################################################

		// stylelint-disable-next-line
		.#{$class_focused} & {
			color: rgba($cgp_font_color, 0.4);
			font-size: 13px;
			font-weight: 500;
			padding-left: 16px;
			transform: translate(16px, -24px);
		}

		// ##################################################

		// stylelint-disable-next-line
		.#{$class_validation_failed} & {
			color: $cgp_validation_failed;
		}

		// ##################################################
		// Blue circle

		&::before {
			content: '';
			width: 9px;
			height: 9px;
			background-color: $cgp_blue_light;
			border-radius: 100%;
			position: absolute;
			left: 0;
			top: 7px;
			transition: all 0.3s ease;

			// Mobile
			@media #{$breakpoint-xs-only} {
				top: 5px;
			}

			// stylelint-disable-next-line
			.#{$class_focused} & {
				width: 8px;
				height: 8px;
				top: 4px;
			}

			// stylelint-disable-next-line
			.#{$class_validation_failed} & {
				background-color: $cgp_validation_failed;
			}
		}
	}

	// ##################################################

	&__input {
		width: 100%;
		height: 48px;
		background-color: rgba($cgp_white, 0.15);
		border-radius: 50px;
		border: 0;
		color: $cgp_blue_light_2;
		font-family: $font_primary;
		font-size: 20px;
		font-weight: 700;
		letter-spacing: 1.6px;
		padding: 0 18px;

		// stylelint-disable-next-line
		.#{$class_validation_failed} & {
			color: $cgp_validation_failed;
		}
	}

	// ##################################################

	textarea {
		resize: none;
	}

	// ##################################################

	&.is-disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}
