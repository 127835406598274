// **************************************************
// Section Footer
// **************************************************

@import '../../../scss/import';

.section-footer {
	position: relative;
	width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: $cgp_blue_light;
	letter-spacing: 0.32px;

	// Mobile
	@media #{$breakpoint-xs-only} {
		flex-direction: column;
	}

	// ##################################################

	&__text-left,
	&__text-right {
		position: relative;
		z-index: 4;
	}

	// ##################################################

	&__text-left {
		margin-right: 16px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin: 0 0 8px;
		}
	}

	// ##################################################

	&__icon {
		margin-right: 16px;
		position: relative;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin: 0 0 8px;
		}

		// ##################################################
		// Background Circle

		&::after,
		&::before {
			@extend %content;
			@include horizontal-vertical-align(absolute);
			background-color: rgba($cgp_blue_light_2, 0.25);
			height: calc(100% + 160px);
			width: calc(100% + 160px);
			opacity: 0.2;
			border-radius: 100%;

			// Tablet P
			@media #{$breakpoint-sm-only} {
				height: calc(100% + 100px);
				width: calc(100% + 100px);
			}

			// Mobile
			@media #{$breakpoint-xs-only} {
				height: calc(100% + 90px);
				width: calc(100% + 90px);
			}
		}

		// ##################################################

		&::after {
			height: calc(100% + 246px);
			width: calc(100% + 246px);
			opacity: 0.15;

			// Tablet P
			@media #{$breakpoint-sm-only} {
				height: calc(100% + 150px);
				width: calc(100% + 150px);
			}

			// Mobile
			@media #{$breakpoint-xs-only} {
				height: calc(100% + 130px);
				width: calc(100% + 130px);
			}
		}

		// ##################################################

		.icon {
			&::after {
				@extend %content;
				@include horizontal-vertical-align(absolute);
				height: 82px;
				width: 82px;
				background-color: rgba($cgp_blue_light_2, 0.25);
				border-radius: 100%;
				opacity: 0.4;

				// Tablet P
				@media #{$breakpoint-sm-only} {
					height: 70px;
					width: 70px;
				}

				// Mobile
				@media #{$breakpoint-xs-only} {
					height: 60px;
					width: 60px;
				}
			}
		}
	}
}
