// **************************************************
// Birth Date
// **************************************************

@import '../../../scss/import';

.birth-date {
	margin-top: 28px;
	max-width: 540px;
	width: 100%;
	text-align: center;

	// ##################################################

	&__input {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 15px;
		@include placeholder($cgp_font_color, 0.55);

		// ##################################################

		&-field {
			@extend %fontsize21;
			font-weight: 700;
			text-align: center;
			color: $cgp_blue_light_2;
			width: 100%;
			height: 48px;
			border: 0;
			background-color: rgba($cgp_white, 0.15);
			border-radius: 50px;
			padding: 0 10px;
		}
	}

	// ##################################################

	&__info-text {
		color: $cgp_validation_failed;
		position: relative;
		display: inline-block;
		padding-left: 22px;
		margin-bottom: 10px;

		// ##################################################
		// Info icon
		&::before {
			content: '!';
			width: 14px;
			height: 14px;
			background-color: $cgp_validation_failed;
			border-radius: 100%;
			position: absolute;
			left: 0;
			top: 4px;
			color: $cgp_font_color;
			font-size: 13px;
			line-height: 1.1;
			font-weight: 700;
		}
	}
}
