// **************************************************
// Particles
// **************************************************

@import '../../../scss/import';

.particles {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 0;
	overflow: hidden;
	pointer-events: none;
	opacity: 0.18;
}
