// **************************************************
// Background Circle
// **************************************************

@import '../../../scss/import';

// Style
.background-circle {
	position: relative;
	width: 106px;
	height: 106px;
	background-color: rgba($cgp_white, 0.25);
	border-radius: 100%;
	pointer-events: none;
	z-index: -1;
	opacity: 0.4;

	// Tablet L
	@media #{$breakpoint-md-only} {
		height: 90px;
		width: 90px;
	}

	// Tablet P
	@media #{$breakpoint-sm-only} {
		height: 80px;
		width: 80px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		height: 50px;
		width: 50px;
	}

	// ##################################################
	// Background Circle

	&::after,
	&::before {
		@extend %content;
		@include horizontal-vertical-align(absolute);
		background-color: $cgp_white;
		border-radius: 100%;
		height: calc(100% + 130%);
		width: calc(100% + 130%);
		z-index: 2;
		opacity: 0.25;
	}

	// ##################################################

	&::after {
		height: calc(100% + 210%);
		width: calc(100% + 210%);
		z-index: 1;
		opacity: 0.15;
	}
}
