//*******************************************************************************
// PLACEHOLDERS
//*******************************************************************************

// General extensions
%content {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	pointer-events: none;
}

// ##################################################

%reset_button {
	background: transparent;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	padding: 0;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
}

// ##################################################

%abs_pos_fullwidth {
	height: 100%;
	left: 0;
	position: absolute;
	width: 100%;
	top: 0;
	pointer-events: none;
}

// ##################################################

%flex_center_center {
	display: flex;
	align-items: center;
	justify-content: center;
}

// ##################################################

%sr_only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	white-space: nowrap;
	border: 0;
	visibility: inherit;
	clip: rect(0, 0, 0, 0);
}

// ##################################################
// FONT SIZES
// ##################################################

// General page text - Paragraph
%page_text {
	font-size: 16px;
	font-weight: 500;
	line-height: 1.31;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 14px;
	}
}

// ##################################################

%title {
	font-weight: 800;
	line-height: 1.2;
	font-family: $font_secondary;
}

// ##################################################

%title_h1 {
	@extend %title;
	font-size: 72px;
	line-height: 1.01;
	letter-spacing: 5.04px;
	text-shadow: 0 7px 0 rgba(0, 161, 228, 0.54);
	text-transform: uppercase;

	// Tablet L
	@media #{$breakpoint-md-only} {
		font-size: 62px;
	}

	// Tablet P
	@media #{$breakpoint-sm-only} {
		font-size: 50px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 40px;
	}

	// Mobile xs
	@media (max-width: 360px) {
		font-size: 32px;
	}
}

// ##################################################

%title_h2 {
	@extend %title;
	text-shadow: 0 7px 0 rgba(0, 161, 228, 0.54);
	font-size: 48px;
	letter-spacing: 3.36px;
	line-height: 1.1;
	text-transform: uppercase;

	// Tablet L
	@media #{$breakpoint-md-only} {
		font-size: 38px;
	}

	// Tablet P
	@media #{$breakpoint-sm-only} {
		font-size: 34px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 26px;
	}
}

// ##################################################

%title_h3 {
	@extend %title;
	font-size: 29px;
	text-shadow: 0 5px 0 rgba(0, 66, 136, 0.26);
	letter-spacing: 0.87px;
	line-height: 1.17;
	text-transform: uppercase;

	// Tablet
	@media #{$breakpoint-sm-md-only} {
		font-size: 25px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 18px;
	}
}

// ##################################################

%title_h4 {
	@extend %title;
	font-size: 20px;
	letter-spacing: 0.4px;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 16px;
	}
}

// ##################################################

%title_h5 {
	@extend %title;
	font-size: 18px;
	line-height: 1.44;
}

// ##################################################

%title_h6 {
	@extend %title;
	font-size: 16px;
	line-height: 1.44;
}

// ##################################################

%fontsize37 {
	font-size: 37px;
	letter-spacing: 1.11px;
	line-height: 1.19;
	text-shadow: 0 5px 0 rgba(0, 66, 136, 0.26);

	// Tablet
	@media #{$breakpoint-sm-md-only} {
		font-size: 25px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 18px;
	}
}

// ##################################################

%fontsize31 {
	font-size: 31px;
	text-shadow: 0 5px 0 rgba(0, 66, 136, 0.26);
	font-weight: 700;
	font-style: normal;
	letter-spacing: 0.93px;
	line-height: 1.16;

	// Tablet
	@media #{$breakpoint-sm-md-only} {
		font-size: 25px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 18px;
	}
}

// ##################################################

%fontsize28 {
	font-size: 28px;
	letter-spacing: 0.84px;
	line-height: 1.04;
	font-weight: 600;
	text-shadow: 0 5px 0 rgba(0, 66, 136, 0.26);

	// Tablet
	@media #{$breakpoint-sm-md-only} {
		font-size: 25px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 18px;
	}
}

// ##################################################

%fontsize24 {
	font-size: 24px;
	letter-spacing: 0.48px;
	line-height: normal;
	text-shadow: 0 0 6px rgba(0, 0, 0, 0.34);

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 20px;
	}
}

// ##################################################

%fontsize21 {
	font-size: 21px;
	font-weight: 700;
	letter-spacing: 2.1px;
	text-transform: uppercase;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 18px;
	}
}

// ##################################################

%fontsize20 {
	text-shadow: 0 0 6px rgba(0, 0, 0, 0.34);
	font-size: 20px;
	letter-spacing: 0.4px;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 18px;
	}
}

// ##################################################

%fontsize18 {
	font-size: 18px;
	letter-spacing: 0.36px;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 16px;
		letter-spacing: 0;
	}
}

// ##################################################

%fontsize16 {
	@extend %page_text;
	letter-spacing: 0.32px;

	// Mobile
	@media #{$breakpoint-xs-only} {
		letter-spacing: 0;
	}
}

// ##################################################

%fontsize14 {
	font-size: 14px;
	line-height: 1.29;
}

// ##################################################

%fontsize13 {
	font-size: 13px;
	letter-spacing: 0.26px;
	line-height: 1.23;
}

// ##################################################

%fontsize9 {
	font-size: 9px;
	letter-spacing: 0.18px;
	font-weight: 700;
}

// ##################################################

%inherit_textstyles {
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
	line-height: inherit;
	text-transform: inherit;
	text-align: inherit;
	font-style: inherit;
	font-family: inherit;
	background-color: transparent;
}

// ##################################################

%backface {
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
}

// ##################################################

%focus_visible {
	outline-offset: 0;
	outline: 2.5px solid $cgp_black;
}

// ##################################################

%bg_video {
	position: absolute;
	min-width: 100%;
	min-height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: $cgp_black;
}
