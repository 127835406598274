// **************************************************
// 0.0 Icons
// **************************************************

@import '../../../scss/import';

// General Icons
.icon {
	// background: transparent no-repeat scroll center / contain;
	display: block;
	position: relative;

	// Icons for hover effect
	&::before {
		background-color: var(--icon-color, $cgp_font_color);
		-webkit-mask-size: contain;
		mask-size: contain;
		-webkit-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
		-webkit-mask-position: center;
		mask-position: center;
		@extend %content;
		transition: background-color 0.3s ease;
	}

	// ##################################################

	&-chevron-up,
	&-chevron-down {
		width: 12px;
		height: 6px;

		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$chevron_down}');
			mask-image: url('data:image/svg+xml,#{$chevron_down}');
		}
	}

	// ##################################################

	&-chevron-right,
	&-chevron-left {
		width: 7px;
		height: 10px;

		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$chevron_right}');
			mask-image: url('data:image/svg+xml,#{$chevron_right}');
		}
	}

	// ##################################################

	&-arrow-right,
	&-arrow-left {
		width: 20px;
		height: 20px;
		background: url('data:image/svg+xml, #{$arrow_right}') no-repeat center / contain;

		&::before {
			background: transparent;
		}
	}

	// ##################################################

	&-chevron-up,
	&-chevron-left,
	&-arrow-left {
		transform: scale(-1);
	}

	// ##################################################

	&-check {
		width: 12px;
		height: 8px;

		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$icon_check}');
			mask-image: url('data:image/svg+xml,#{$icon_check}');
		}
	}

	// ##################################################

	&-cross {
		width: 10px;
		height: 8px;

		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$icon_cross}');
			mask-image: url('data:image/svg+xml,#{$icon_cross}');
		}
	}

	// ##################################################

	&-music {
		width: 78px;
		height: 28px;
		opacity: 0.25;

		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$icon_music}');
			mask-image: url('data:image/svg+xml,#{$icon_music}');
		}
	}

	// ##################################################

	&-earphone {
		width: 22px;
		height: 24px;

		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$icon_earphone}');
			mask-image: url('data:image/svg+xml,#{$icon_earphone}');
		}
	}

	// ##################################################

	&-ticket {
		width: 95px;
		height: 66px;

		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$icon_ticket}');
			mask-image: url('data:image/svg+xml,#{$icon_ticket}');
		}
	}

	// ##################################################

	&-crossed-fingers {
		width: 64px;
		height: 102px;

		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$icon_crossed_fingers}');
			mask-image: url('data:image/svg+xml,#{$icon_crossed_fingers}');
		}
	}
}
