//**********************************************************
// Intro screen
//**********************************************************

@import '../../../scss/import';

.intro-screen {
	position: relative;
	text-align: center;
	overflow: hidden;
	padding: 32px 0;

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 0 20px 40px;
	}

	// ##################################################

	.container {
		max-width: 580px;
		position: relative;
	}

	// ##################################################

	&__header {
		display: flex;
		align-items: center;
		flex-direction: column;
		position: relative;

		// ##################################################

		.icon {
			display: inline-block;
			margin-top: 32px;
		}

		// ##################################################

		.background-circle {
			height: 50px;
			width: 50px;
			position: absolute;
			left: 0;
			bottom: 32%;

			// Mobile
			@media #{$breakpoint-xs-only} {
				height: 30px;
				width: 30px;
			}
		}
	}

	// ##################################################

	&__info-text {
		opacity: 0.73;
		color: $cgp_white;
		font-size: 15px;
		font-weight: 400;
		letter-spacing: 0.3px;
		margin-top: 10px;
	}

	// ##################################################

	&__button {
		margin: 34px 0;
	}

	// ##################################################

	.section-footer {
		padding-left: 32px;
		margin-top: auto;

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding-left: 0;
		}
	}

	// ##################################################

	&__bg-element {
		// ##################################################

		.background-circle {
			position: absolute;
			left: -44%;
			top: 33%;

			// Tablet P and Down
			@media #{$breakpoint-sm-down} {
				left: -10%;
			}

			// ##################################################

			&:nth-child(2) {
				left: inherit;
				right: -44%;

				// Desktop and Up
				@media #{$breakpoint-lg-up} {
					width: 110px;
					height: 110px;
				}

				// Tablet P and Down
				@media #{$breakpoint-sm-down} {
					right: -10%;
				}
			}
		}
	}
}
