// **************************************************
// Checkbox
// **************************************************

@import '../../../scss/import';

.checkbox {
	min-height: 30px;
	letter-spacing: 0.32px;

	& + .checkbox {
		margin-top: 18px;
	}

	// ##################################################

	&__label {
		color: rgba($cgp_white, 0.7);
		display: inline-block;
		position: relative;
		padding-left: 42px;
		cursor: pointer;
		margin: 0;
		transition: color 0.3s ease;

		// ##################################################

		// Unchecked cricle
		&::before {
			content: '';
			position: absolute;
			width: 32px;
			height: 32px;
			background-color: rgba($cgp_blue_light, 0.3);
			border-radius: 100%;
			left: 0;
			top: -5px;
		}

		// ##################################################

		// Checked circle
		&::after {
			content: '';
			position: absolute;
			left: 8px;
			top: 3px;
			width: 16px;
			height: 16px;
			box-shadow: 0 0 20px #162650;
			background-color: $cgp_blue_light;
			border-radius: 100%;
			transition: transform 0.3s ease;
			transform: scale(0);
		}
	}

	// ##################################################

	a {
		border: 0;
		text-decoration: underline;
	}

	// ##################################################

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;

		&:checked + .checkbox__label {
			color: $cgp_font_color;

			&::after {
				@include scale(1);
			}
		}
	}
}
