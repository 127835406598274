// **************************************************
// Passes
// **************************************************

@import '../../../scss/import';

.passes {
	font-family: $font_primary;
	font-weight: 500;
	max-width: 460px;
	margin: 0 auto;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: left;

	// Mobile
	@media #{$breakpoint-xs-only} {
		align-items: flex-start;
	}

	// Mobile ExtraSmall
	@media (max-width: 320px) {
		flex-direction: column;
	}

	// ##################################################

	&__icon {
		height: 76px;
		width: 76px;
		border-radius: 100%;
		background-color: $cgp_white;
		color: $cgp_blue;
		flex-direction: column;
		border: 8px solid rgba($cgp_blue_light_1, 0.8);
		text-align: center;

		// Mobile ExtraSmall
		@media (max-width: 320px) {
			border-width: 6px;
		}

		// ##################################################

		.icon {
			width: 26px;
			height: 18px;
		}
	}

	// ##################################################

	&__text {
		margin-top: 4px;
	}

	// ##################################################

	&__item {
		display: grid;
		grid-template-columns: 76px 1fr;
		grid-gap: 10px;
		align-items: center;
		max-width: 176px;
		width: 100%;
		flex: 0 0 176px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			flex: auto;
			grid-template-columns: unset;
			grid-template-rows: 76px 1fr;
			text-align: center;
			justify-items: center;
		}

		// Mobile ExtraSmall
		@media (max-width: 320px) {
			grid-template-columns: 76px 1fr;
			text-align: left;
			max-width: 100%;
			grid-template-rows: unset;
			justify-items: flex-start;
		}

		// ##################################################

		&:not(:last-child) {
			margin-right: 40px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				margin-right: 20px;
			}

			// Mobile ExtraSmall
			@media (max-width: 320px) {
				margin: 0 0 20px;
			}
		}
	}

	// ##################################################

	&__label {
		letter-spacing: 0.28px;
	}
}
