//*******************************************************************************
// WEBFONTS : Roboto
//*******************************************************************************

@font-face {
	font-family: 'Roboto';
	src: local('Roboto'), local('Roboto-Regular'), url('../fonts/Roboto-Regular.woff2') format('woff2'), url('../fonts/Roboto-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: local('Roboto Medium'), local('Roboto-Medium'), url('../fonts/Roboto-Medium.woff2') format('woff2'), url('../fonts/Roboto-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: local('Roboto Bold'), local('Roboto-Bold'), url('../fonts/Roboto-Bold.woff2') format('woff2'), url('../fonts/Roboto-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

//*******************************************************************************
// WEBFONTS : Montserrat
//*******************************************************************************

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat Bold'), local('Montserrat-Bold'), url('../fonts/Montserrat-Bold.woff2') format('woff2'),
		url('../fonts/Montserrat-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'), url('../fonts/Montserrat-ExtraBold.woff2') format('woff2'),
		url('../fonts/Montserrat-ExtraBold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}
