// **************************************************
// Site Header
// **************************************************

@import '../../../scss/import';

.header-main {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	padding: 16px 30px;
	z-index: $zindex_header;

	// Mobile
	@media #{$breakpoint-xs-only} {
		top: inherit;
		bottom: 0;
		padding: 30px 15px;
		display: none;
	}
}
