// **************************************************
// Radiobox Inline
// **************************************************

@import '../../../scss/import';

.radiobox-inline {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-top: 38px;

	// Mobile
	@media #{$breakpoint-xs-only} {
		margin-top: 28px;
		display: flex;
		flex-direction: column;
	}

	// ##################################################

	&__label {
		margin-bottom: 0;
		color: $cgp_blue_light;
	}

	// ##################################################

	&__input {
		display: inline-flex;
		align-items: center;
		margin-left: 22px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin: 15px 0 0;
		}
	}

	// ##################################################

	.radio-button {
		margin-right: 22px;
	}

	// ##################################################

	&__text {
		margin-top: 4px;
	}
}
