// **************************************************
// Cookie banner
// **************************************************

@import '../../../scss/import';

// Style
.cookie-banner {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	padding: 28px 0;
	z-index: $zindex_cookie_notice;
	background-color: $cgp_blue;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.5s ease, visibility 0.3s ease;
	font-size: 16px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

	// Tablet P and Down
	@media #{$breakpoint-sm-down} {
		padding: 18px 0;
	}

	// If Cookie is not set
	// stylelint-disable-next-line
	&.#{$class_cookie_not_set} {
		opacity: 1;
		visibility: visible;
	}

	// ##################################################

	&__text {
		padding-left: 24px;
		line-height: 1.5;

		// Tablet L and Down
		@media #{$breakpoint-md-down} {
			padding: 0;
		}
	}

	// ##################################################

	&__right {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		// Mobile
		@media #{$breakpoint-xs-only} {
			flex-direction: column;
			justify-content: center;
		}
	}

	// ##################################################

	&__inner {
		display: grid;
		grid-template-columns: 68% 1fr;
		grid-gap: 28px;
		align-items: center;

		// Tablet L
		@media #{$breakpoint-md-only} {
			grid-template-columns: 56% 1fr;
		}

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			grid-template-columns: 1fr;
			grid-gap: 20px;
		}
	}

	// ##################################################

	.btn {
		font-size: inherit;
	}

	// ##################################################

	&__settings-link {
		position: relative;
		display: block;
		margin-right: 36px;
		transition: opacity 0.3s ease, color 0.3s ease;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin: 0 0 20px;
		}

		// ##################################################
		// Bottom line
		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			width: 0;
			left: 0;
			height: 2px;
			background-color: $cgp_white;
			pointer-events: none;
			transition: width 0.3s ease;
		}

		// ##################################################

		&:hover {
			opacity: 0.8;

			&::before {
				width: 100%;
			}
		}
	}
}
