// **************************************************
// Participate Module
// **************************************************

@import '../../../scss/import';

.participate {
	overflow: hidden;
	position: relative;
	text-align: center;
	padding: 0 20px;

	// ##################################################

	.container {
		max-width: 862px;
		position: relative;
	}

	// ##################################################

	&__header {
		position: relative;
		margin-bottom: 36px;

		// ##################################################

		.icon {
			margin: 30px auto 0;

			// Mobile
			@media #{$breakpoint-xs-only} {
				margin-top: 20px;
			}
		}

		// ##################################################

		.background-circle {
			height: 50px;
			width: 50px;
			position: absolute;
			left: 18%;
			bottom: 13%;

			// Mobile
			@media #{$breakpoint-xs-only} {
				height: 30px;
				width: 30px;
			}
		}
	}

	// ##################################################

	&__content {
		position: relative;
		border: 1px solid $cgp_gray;
		background-color: $cgp_white;
		border-radius: 36px 36px 0 0;
		color: $cgp_blue;
		padding: 80px 80px 100px;
		flex: auto;

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			padding: 50px 50px 80px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding: 30px 20px 80px;
			border-radius: 20px 20px 0 0;
		}

		// ##################################################

		.icon {
			position: absolute;
			bottom: 42px;
			left: 50%;
			transform: translateX(-50%);

			// Tablet P and Down
			@media #{$breakpoint-sm-down} {
				bottom: 30px;
			}
		}

		// ##################################################

		.background-circle {
			position: absolute;
			left: -15%;
			top: 7%;

			&:nth-child(2) {
				left: inherit;
				right: -16%;
			}
		}
	}

	// ##################################################

	.title--h3 {
		text-transform: none;
		font-weight: 700;
		text-shadow: none;
	}

	// ##################################################

	.btn {
		margin-top: 66px;

		// Desktop and Up
		@media #{$breakpoint-lg-up} {
			padding: 6px 32px;
		}

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			margin-top: 30px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			font-size: 14px;
		}

		// ##################################################
		// Hover/Focus

		&:hover,
		&:focus {
			box-shadow: 0 0 0 3px rgba($cgp_blue_light, 0.3);
		}
	}

	// ##################################################

	.editor-text {
		max-width: 645px;
		width: 100%;
		margin: 60px auto 0;
		letter-spacing: 0.32px;

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			margin-top: 30px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: 100%;
			margin-top: 20px;
		}
	}
}
