// **************************************************
// Footer
// **************************************************

@import '../../../scss/import';

.footer-main {
	width: 100%;
	padding: 10px 0;

	// ##################################################

	&__nav {
		// ##################################################

		ul {
			display: flex;
			width: 100%;
			justify-content: space-between;
		}

		// ##################################################

		li {
			display: inline-block;
			margin: 0 5px;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}

		// ##################################################

		&-link {
			display: block;
			color: $cgp_white;
			font-weight: 700;
			letter-spacing: 0.64px;
			text-transform: uppercase;
			transition: color 0.3s ease, opacity 0.3s ease;

			// Mobile
			@media #{$breakpoint-xs-only} {
				font-size: 12px;
			}

			// Smaller screen
			@media (max-width: 360px) {
				font-size: 8px;
			}

			// ##################################################

			&:hover {
				opacity: 0.8;
			}
		}
	}
}
