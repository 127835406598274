// **************************************************
// Menu
// **************************************************

@import '../../../scss/import';

.menu {
	// ##################################################

	li {
		display: inline-block;
		margin: 0 11px;

		// Smaller screens
		@media (max-width: 380px) {
			margin: 0 5px;
		}
	}

	// ##################################################

	&__link {
		display: block;
		color: $cgp_white;
		font-weight: 700;
		letter-spacing: 0.64px;
		text-transform: uppercase;
		transition: color 0.3s ease, opacity 0.3s ease;

		// ##################################################

		&:hover {
			opacity: 0.8;
		}
	}
}
