// **************************************************
// Agreement
// **************************************************

@import '../../../scss/import';

.agreement {
	max-width: 454px;
	margin-top: 36px;
}
