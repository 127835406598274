// **************************************************
// Game Success Module
// **************************************************

@import '../../../scss/import';

.game-success {
	width: 100%;
	text-align: center;
	overflow: hidden;
	padding: 56px 0 42px;

	// Tablet P and Down
	@media #{$breakpoint-sm-down} {
		max-width: 100%;
		padding: 56px 40px 42px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 0 20px 20px;
	}

	// ##################################################

	.container {
		max-width: 870px;
		position: relative;

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			max-width: 580px;
		}
	}

	// ##################################################

	&__header {
		// ##################################################

		.icon {
			margin: 14px auto 0;
		}
	}

	// ##################################################

	&__content {
		position: relative;
		max-width: 755px;
		width: 100%;
		margin: 14px auto 80px;

		// Tablet L
		@media #{$breakpoint-md-down} {
			max-width: 682px;
		}

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			margin-bottom: 50px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-bottom: 30px;
		}
	}

	// ##################################################

	.game-circle {
		margin: 105px auto 0;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 60px;
		}
	}

	// ##################################################

	.editor-text {
		max-width: 336px;
		width: 100%;
		margin: 96px auto 0;
		color: $cgp_blue_light_3;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 60px;
		}
	}

	// ##################################################

	.btn {
		margin-top: 32px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 24px;
		}
	}

	// ##################################################

	.section-footer {
		margin-top: auto;
		padding-left: 42px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding-left: 0;
		}
	}

	// ##################################################

	.title--h3 {
		text-transform: none;
		@extend %fontsize37;
	}

	// ##################################################

	&__bg-element {
		// ##################################################

		.background-circle {
			position: absolute;
			left: -21%;
			top: 28%;

			// Tablet P and Down
			@media #{$breakpoint-sm-down} {
				left: -10%;
			}

			// ##################################################

			&:nth-child(1) {
				// Desktop and Up
				@media #{$breakpoint-lg-up} {
					width: 126px;
					height: 126px;
				}
			}

			// ##################################################

			&:nth-child(2) {
				left: inherit;
				right: -32%;
				top: 8%;

				// Tablet P and Down
				@media #{$breakpoint-sm-down} {
					right: -10%;
				}
			}

			// ##################################################

			&:nth-child(3) {
				width: 76px;
				height: 76px;
				left: inherit;
				right: 0;
				top: inherit;
				bottom: 20%;

				// Tablet P
				@media #{$breakpoint-sm-only} {
					width: 50px;
					height: 50px;
					right: 0;
				}

				// Mobile
				@media #{$breakpoint-xs-only} {
					height: 30px;
					width: 30px;
				}
			}
		}
	}
}
